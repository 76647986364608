import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiGet, apiPost } from "../../helpers/api";
import { HttpStatusCode } from "axios";


export const findAllParticipantsThunkAction = createAsyncThunk(
    'participant/find-all',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const allParticipants = await apiGet('/participant');

            return fulfillWithValue(allParticipants)
        } catch (e) {
            console.error('participant/find-all', e);
            return rejectWithValue([])
        }
    }
)

export const participantLoginThunkAction = createAsyncThunk(
    'participant/login',
    async ({ email, plan_link }, { fulfillWithValue, rejectWithValue }) => {
        const response = await apiPost('/participant/login', { email, plan_link });

        if (response.status !== 200) {
            return rejectWithValue(response)
        }

        return fulfillWithValue(response);
    }
)

export const participantLogoutThunkAction = createAsyncThunk(
    '/participant/logout',
    async (_, { rejectWithValue }) => {
        try {
            const response = await apiPost('/participant/logout', { token: localStorage.getItem("participant_token") })

            if (response.status === HttpStatusCode.Ok) {
                localStorage.removeItem("participant_token");
                window.location.reload();
            } else {
                rejectWithValue("PARTICIPANT_LOGOUT_FAIL")
            }

        } catch (e) {
            rejectWithValue(e);
        }
    }
)

export const getSelectableDatesThunkAction = createAsyncThunk(
    '/participant/get-selectable-dates',
    async ({ plan_link, token }, { fulfillWithValue, rejectWithValue }) => {

        let url = '/participant/get-selectable-dates'

        if (plan_link) {
            url += `?plan_link=${plan_link}`
        }

        if (token) {
            url += `?token=${token}`;
        }


        const response = await apiGet(url);
        if (response.status !== 200) {
            rejectWithValue(response)
        }

        return fulfillWithValue(response)
    }
)

export const saveParticipantDatesThunkAction = createAsyncThunk(
    '/update-selected-dates',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        const response = await apiPost('/participant/update-selected-dates', data);
        if (response !== 201) {
            return rejectWithValue(response)
        }

        return fulfillWithValue(response);
    }
)

export const createParticipantThunkAction = createAsyncThunk(
    '/participant/add',
    async (data, { fulfillWithValue, rejectWithValue }) => {
        try {
            const response = await apiPost('/participant', data);
            return fulfillWithValue(response);
        } catch (e) {
            return rejectWithValue()
        }
    }
)