import { Tabs } from "antd";
import PlanDetailView from "./plan-detail-view";
import ParticipantDateSelectionView from "../participant/participant-date-selection-view";

const PlanDetailTabView = ({ selectedPlan, planLinkData, plan_dates }) => {
    return (
        <Tabs
            defaultActiveKey="1"
            items={[
                {
                    label: 'Plan Detay',
                    key: '1',
                    children: <PlanDetailView selectedPlan={selectedPlan} />,
                },
                {
                    label: 'Takvim',
                    key: '2',
                    children: <ParticipantDateSelectionView selectedPlan={selectedPlan} plan_dates={plan_dates} planLinkData={planLinkData} isResponsibleView />,
                }
            ]}
        />
    )
}

export default PlanDetailTabView;

