import { memo, useEffect, useMemo, useRef, useState } from "react";
import { Badge, Button, Card, Typography, Col, Descriptions, Row, Table, Tag, Timeline, Divider, Modal, Alert, Tooltip, Form, Input, Checkbox, Select } from "antd";
import { ClockCircleOutlined, CopyOutlined, EditOutlined } from "@ant-design/icons";
import TPEditor from "../../components/tp-editor/tp-editor";
import { format } from 'date-fns';
import CopyToClipboard from "react-copy-to-clipboard";
import TPCardTitle from "../../components/tp-card-title/tp-card-title";
import TextArea from "antd/es/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { deletePlanParticipantThunkAction, updatePlanParticipantPriorityThunkAction, updatePlanParticipantThunkAction, updatePlanThunkAction } from "../../store/plan/plan-thunk.action";
import TPSelect from "../../components/tp-select/tp-select";
import { createParticipantThunkAction } from "../../store/participant/participant-thunk-action";
import AddNewParticipantModalContentView from "../participant/add-new-participant-modal-content-view";

const PlanDetailView = ({ selectedPlan }) => {

    // refs
    const updatePlaFormnRef = useRef();
    const addNewParticipantFormRef = useRef();

    // redux
    const dispatch = useDispatch();
    const participantSelectData = useSelector(state => state.participantSlice.participants)


    // local States
    const [isMailContentModalOpen, setIsMailContentModalOpen] = useState(false);
    const [isLinkCreateModalOpen, setIsLinkCreateModalOpen] = useState(false);
    const [isPlanSummaryUpdateModalOpen, setIsPlanSummaryUpdateModalOpen] = useState(false);
    const [isPlanParticipantUpdateOpen, setIsPlanParticipantUpdateOpen] = useState(false);
    const [selectedParticipant, setSelectedParticipant] = useState();
    const [showAddNewParticipantModal, setShowAddNewParticipantModal] = useState(false);
    const [isLinkCopied, setIsLinkCopied] = useState(false);
    const [addNewParticipantTooltipActions, setAddNewParticipantTooltipActions] = useState({ title: "Ekle" });
    const [addNewButtonDisabled, setAddNewButtonDisabled] = useState(true);
    const [isPriorityChanged, setIsPriorityChange] = useState(false);
    const [priorityChanges, setPriorityChanges] = useState([]);
    const [participantTableData, setParticipantTableData] = useState(selectedPlan.participants)

    useEffect(() => {
        if (selectedParticipant && selectedPlan?.participants) {
            const parsed = JSON.parse(selectedParticipant);
            const isAllReadyAddedToList = selectedPlan.participants.some(p => p.id === parsed.id)
            if (isAllReadyAddedToList) {
                setAddNewParticipantTooltipActions({
                    title: "Bu katılımcı daha önce eklendiği için tekrar eklenemez."
                })
                setAddNewButtonDisabled(true)
            } else {
                setAddNewButtonDisabled(false);
                setAddNewParticipantTooltipActions({
                    title: "Ekle"
                })
            }
        }
    }, [selectedParticipant, selectedPlan?.participants])

    useEffect(() => {
        setParticipantTableData(selectedPlan.participants)
    }, [selectedPlan?.participants])

    useEffect(() => {
        setIsPriorityChange(priorityChanges.some(p => p.changed === true))
    }, [priorityChanges])

    /**
     * Constants
     */
    const createdPlanLink = useMemo(() => `${window.location.origin}/participant/participant-date-selection/${selectedPlan.plan_link}`, [selectedPlan.plan_link])

    const formatDate = (date, isTimeShow = true) => {
        return format(new Date(date), `dd/MM/yyyy ${isTimeShow ? 'HH:mm' : ''}`)
    }

    const onPriortyChange = ({ value, record, initialValue }) => {
        const { participants } = selectedPlan;
        const participantIndex = participants.findIndex(p => p.id === record.id);
        const participant = participants[participantIndex]
        const isChanged = participant.priority_order !== value;

        setPriorityChanges(prev => {
            const prevIndex = prev.findIndex(p => p.participant_id === participant.id);
            if (prevIndex > -1) {
                prev[prevIndex] = {
                    ...prev[prevIndex],
                    value,
                    initialValue,
                    changed: isChanged
                }
            } else {
                prev.push({
                    participant_id: participant.id,
                    value,
                    initialValue,
                    changed: isChanged
                })
            }

            return [...prev];
        })

        setParticipantTableData(prev => {
            const oldData = JSON.parse(JSON.stringify((prev)));
            const participantIndex = oldData.findIndex(p => p.id === record.id);
            oldData[participantIndex].priority_order = value;
            return oldData;
        })
    }

    const cancelPriorityChanges = () => {
        const initialTableData = [];
        for (let index = 0; index < participantTableData.length; index++) {
            const element = participantTableData[index];
            const findIndexFromPriorty = priorityChanges.findIndex(pc => pc.participant_id === element.id);
            if (findIndexFromPriorty > -1) {
                initialTableData.push({
                    ...element,
                    priority_order: priorityChanges[findIndexFromPriorty].initialValue
                })
            } else {
                initialTableData.push(element)
            }
        }
        setParticipantTableData(initialTableData)
        setIsPriorityChange(false);
        setPriorityChanges([])
    }

    const columns = () => {

        const participantTabelColumns = [
            {
                title: 'Unvan',
                dataIndex: 'title'
            },
            {
                title: 'Ad Soyad',
                fixed: "left",
                render: (row) => {
                    return <>{row.name} {row.surname}</>
                }
            },
            {
                title: 'E-Posta',
                dataIndex: 'email'
            },
            {
                title: 'Kurum',
                dataIndex: 'institution',
            },
            {
                title: "İşlemler",
                fixed: "right",
                render: (row) => {
                    return (<Row>
                        <Button disabled={isPlanParticipantUpdateOpen || isPriorityChanged} onClick={() => onParticipantDelete(row.id)} danger>Sil</Button>
                    </Row>)
                }
            }
        ];

        if (selectedPlan.has_priority) {
            const lastColumn = participantTabelColumns.pop();
            participantTabelColumns.push(
                {
                    title: 'Öncelik',
                    key: 'priorty_order',
                    render: (_, record) => (
                        <Select
                            style={{ width: 100 }}
                            placeholder="Öncelik Seçimi"
                            onChange={(value) => onPriortyChange({ value, record, initialValue: record.priority_order })}
                            disabled={isPlanParticipantUpdateOpen}
                            value={record.priority_order}
                            options={[
                                {
                                    value: 'HIGH',
                                    label: 'Yüksek',
                                },
                                {
                                    value: 'MEDIUM',
                                    label: 'Normal',
                                },
                                {
                                    value: 'LOW',
                                    label: 'Düşük',
                                },
                            ]}
                        />
                    )
                },
                lastColumn
            )
        }

        return participantTabelColumns;
    }

    const validationRules = {
        plan_name: [
            {
                required: true,
                message: 'Plan adını girmelisiniz.'
            }
        ],
        description: [
            {
                max: 100,
                message: "En fazla 100 karakter yazabilirsiniz."
            }
        ],
        responsible_id: [
            {
                required: true,
                message: "Plan ile ilgili sorumluyu belirtmelisiniz."
            }
        ],
        meetingAddress: []
    }

    const groupLabel = {
        labels: ['İsim Soyisim', 'Kurum'],
        layout: [12, 6]
    }

    const sendEmail = () => {
        setIsLinkCreateModalOpen(true);
        // ! Api'ye request at mail gönder.
    }

    const copyLink = () => {
        setIsLinkCopied(true);

        setTimeout(() => {
            setIsLinkCopied(false);
        }, 500);
    }

    const PlanSummaryTitle = () => {
        return (
            <TPCardTitle title="Plan Özeti">
                <Row style={{ gap: 10 }}>

                    <Button style={{ display: "flex", alignItems: "center" }} onClick={() => setIsPlanSummaryUpdateModalOpen(true)}>
                        <EditOutlined />
                        Düzenle
                    </Button>
                </Row>
            </TPCardTitle >
        )
    }

    const onAddNewParticipant = () => {
        setShowAddNewParticipantModal(true)
    }

    const onAddNewParticipantFinish = () => {
        const values = addNewParticipantFormRef.current.getFieldsValue();
        dispatch(createParticipantThunkAction(values));
        setShowAddNewParticipantModal(false);
    }

    const onParticipantSelect = (value) => {
        setSelectedParticipant(value);
    }

    const onParticipantTitleButtonClick = () => {
        if (isPriorityChanged) {
            dispatch(updatePlanParticipantPriorityThunkAction(priorityChanges.map(priorityChange => ({
                participant_id: priorityChange.participant_id,
                priority_order: priorityChange.value,
                plan_id: selectedPlan.id
            }))))

            setPriorityChanges([]);
            setIsPriorityChange(false);
        } else {
            setIsPlanParticipantUpdateOpen(true)
        }
    }

    const PlanParticipantTitle = () => {
        return (
            <TPCardTitle title="Katılımcılar">
                {!isPlanParticipantUpdateOpen ?
                    <>
                        <Button danger={isPriorityChanged} type={isPriorityChanged ? "primary" : "dashed"}
                            onClick={onParticipantTitleButtonClick}>
                            {isPriorityChanged ? "Öncelik Değişikliklerini Kaydet" : "Yeni katılımcı ekle"}
                        </Button>
                        {isPriorityChanged && <Button onClick={cancelPriorityChanges} type="link">İptal</Button>}
                    </>
                    :
                    <>
                        <Typography.Text>
                            Aşağıdaki alandan yeni bir katılımcı ekleyebilirsiniz.
                        </Typography.Text>
                        <Row justify={"space-evenly"} style={{ gap: "10px", width: "500px" }}>
                            <Col style={{ width: "350px" }}>
                                <TPSelect
                                    filterOption={(input, option) => (option?.value || '').toLowerCase().includes(input?.trim().toLowerCase())}
                                    addNewText="Yeni Katılımcı Ekle"
                                    onAddNew={onAddNewParticipant}
                                    groupLabel={groupLabel}
                                    options={selectOptions()}
                                    onSelect={onParticipantSelect}
                                    value={selectedParticipant}
                                    isModalOpen={showAddNewParticipantModal}
                                    modalProps={
                                        {
                                            modalTitle: "Yeni Katılımcı Ekle",
                                            onOk: () => addNewParticipantFormRef.current.submit(),
                                            onCancel: () => setShowAddNewParticipantModal(false),
                                            modalContent: <AddNewParticipantModalContentView ref={addNewParticipantFormRef} onFinish={onAddNewParticipantFinish} />
                                        }
                                    }
                                />
                            </Col>
                            <Col>
                                <Tooltip title={addNewParticipantTooltipActions.title} ƒ trigger={"hover"} >
                                    <Button disabled={addNewButtonDisabled} onClick={onParticipantAdd} size="middle" type="primary">Ekle</Button>
                                </Tooltip>
                                &nbsp;
                                <Button onClick={() => setIsPlanParticipantUpdateOpen(false)} size="middle" danger>&times;</Button>
                            </Col>
                        </Row>
                    </>
                }
            </TPCardTitle>
        )
    }

    const onPlanSummaryUpdateFinish = (values) => {
        dispatch(updatePlanThunkAction({
            id: selectedPlan.id,
            responsible_id: selectedPlan.responsible_id,
            plan_type: selectedPlan.plan_type,
            ...values
        }));

        setIsPlanSummaryUpdateModalOpen(false);
    }

    const selectOptions = () => {
        return participantSelectData.map(option => ({
            value: JSON.stringify(option),
            label: (
                <Row justify={"space-between"} align={"middle"} style={{ width: '100%' }}>
                    <Col span={12}>{option.name} {option.surname}</Col>
                    <Col span={6}>{option.institution}</Col>
                </Row>
            )
        }))
    }

    const onParticipantAdd = () => {
        dispatch(updatePlanParticipantThunkAction({
            plan_id: selectedPlan.id,
            participant_id: JSON.parse(selectedParticipant).id
        }));

        setSelectedParticipant(null)
    }

    const onParticipantDelete = async (participant_id) => {

        await dispatch(deletePlanParticipantThunkAction({
            plan_id: selectedPlan.id,
            participant_id
        }))
    }


    return (
        <>
            <Row >
                <Card span={14} title={<PlanSummaryTitle />}>
                    <Descriptions layout="horizontal" bordered column={6}>
                        <Descriptions.Item label="Plan Adı" span={3}>{selectedPlan.plan_name}</Descriptions.Item>
                        <Descriptions.Item label="Sorumlu" span={3}> {`${selectedPlan.responsible.name} ${selectedPlan.responsible.surname}`} </Descriptions.Item>

                        <Descriptions.Item label="Açıklama" span={6}>{selectedPlan.description || <i style={{ color: "gray" }}>Belirtilmedi</i>}</Descriptions.Item>
                        <Descriptions.Item label="Adres" span={6}>{selectedPlan.address > 0 ? selectedPlan.address : <i style={{ color: "gray" }}>Belirtilmedi</i>}</Descriptions.Item>

                        <Descriptions.Item label="Çevrimiçi" span={2}>
                            <Badge status={`${selectedPlan.is_online ? 'success' : 'error'}`} text={`${selectedPlan.is_online ? 'Evet' : 'Hayır'}`} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Katılımcı Önceliği" span={2}>
                            <Badge status={`${selectedPlan.has_priority ? 'success' : 'error'}`} text={`${selectedPlan.has_priority ? 'Evet' : 'Hayır'}`} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Anonim Anket" span={2}>
                            <Badge status={`${selectedPlan.is_anonymous ? 'success' : 'error'}`} text={`${selectedPlan.is_anonymous ? 'Evet' : 'Hayır'}`} />
                        </Descriptions.Item>
                        <Descriptions.Item label="Seçilen Tarihler" span={6}>
                            <br />
                            <Timeline
                                items={Object.values(selectedPlan.plan_dates).sort((a, b) => a.start_date - b.start_date).map(date => {
                                    if (date.is_all_day) {
                                        return {
                                            children:
                                                <Row>
                                                    <Tag style={{ display: 'flex', alignItems: 'center', fontSize: 13, padding: "5px 10px" }} icon={<ClockCircleOutlined />} color="default">
                                                        {`${formatDate(Number(date.start_date), false)} (Tüm Gün)`}
                                                    </Tag>
                                                </Row>
                                        }
                                    }

                                    return {
                                        children: (
                                            <Row>
                                                <Tag style={{ display: 'flex', alignItems: 'center', fontSize: 13, padding: "5px 10px" }} icon={<ClockCircleOutlined />} color="default">
                                                    {formatDate(Number(date.start_date))}
                                                </Tag>
                                                - &nbsp;
                                                <Tag style={{ display: 'flex', alignItems: 'center', fontSize: 13, padding: "5px 10px" }} icon={<ClockCircleOutlined />} color="default">
                                                    {formatDate(Number(date.end_date))}
                                                </Tag>
                                            </Row>
                                        )
                                    }
                                })}
                            />

                        </Descriptions.Item>
                    </Descriptions>
                </Card>

                <Col span={10}>
                    <Card title={<PlanParticipantTitle />} style={{ overflow: "auto" }}>
                        <Descriptions.Item>
                            <Typography.Text>
                                Planınız için aşağıdaki katılımcıları seçtiniz.
                            </Typography.Text>
                        </Descriptions.Item>

                        <Divider />

                        <Table
                            size="middle"
                            pagination={false}
                            columns={columns()}
                            dataSource={participantTableData}
                        />

                        <Divider />

                        <Row align={"middle"} justify={"end"}>
                            <Col>
                                <Button onClick={sendEmail} type="primary" > Plan Bağlantısını Göster </Button>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Modal
                title={<Typography.Title level={3}>
                    Mail İçeriği
                </Typography.Title>}
                centered
                open={isMailContentModalOpen}
                onOk={() => setIsMailContentModalOpen(false)}
                onCancel={() => setIsMailContentModalOpen(false)}
                okText="Gönder"
                cancelText="Vazgeç"
                width={700}
            >
                <Col>
                    <Typography.Text>
                        Seçili kişilere gönderilecek mail içeriğini giriniz.
                    </Typography.Text>
                </Col>
                <Divider />
                <Col>
                    <TPEditor
                        name="description"
                        onChange={(data) => {
                            console.log('data', data);
                        }}
                    />
                </Col>

            </Modal>

            <Modal
                title={
                    <Typography.Title level={3}>
                        Katılımcı Link Paylaşımı
                    </Typography.Title>
                }
                centered
                open={isLinkCreateModalOpen}
                onOk={() => setIsLinkCreateModalOpen(false)}
                okText="Kapat"
                cancelButtonProps={{ style: { display: "none" } }}
                width={700}
                onCancel={() => setIsLinkCreateModalOpen(false)}
            >
                <Divider />
                <Col>
                    <Row align={"middle"} justify={"center"} style={{ flexDirection: 'column' }}>
                        <Col>Aşağıdaki linki kullanarak katılımcılara davet gönderebilirsiniz.</Col>
                        <br />
                        <Col>
                            <Row align={"middle"} justify={"center"} style={{ flexBasis: "auto", marginBottom: "30px" }}>
                                <Alert type="success" message={createdPlanLink} style={{ width: "500px", height: 40, lineHeight: "15px" }} />
                                &nbsp;
                                <CopyToClipboard text={createdPlanLink}
                                    onCopy={copyLink}>
                                    <Tooltip title={isLinkCopied ? "Kopyalandı" : "Kopyala"} ƒ trigger={"hover"} >
                                        <Button style={{ height: 40 }}>
                                            {isLinkCopied ? "Kopyalandı" : <CopyOutlined style={{ width: "1.2em", height: "1.2em" }} />}
                                        </Button>
                                    </Tooltip>
                                </CopyToClipboard>

                            </Row>
                        </Col>
                    </Row>
                </Col>

            </Modal>

            {/* Planı düzenle */}
            <Modal
                title={(
                    <Typography.Title level={3}>
                        Planı Düzenle
                    </Typography.Title>
                )}
                centered
                open={isPlanSummaryUpdateModalOpen}
                onOk={() => updatePlaFormnRef.current.submit()}
                onCancel={() => setIsPlanSummaryUpdateModalOpen(false)}
                okText="Kaydet"
                cancelText="Vazgeç"
                width={700}>

                <Form
                    ref={updatePlaFormnRef}
                    name="updatePlan"
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    initialValues={{ ...selectedPlan }}
                    onFinish={onPlanSummaryUpdateFinish}
                    onFinishFailed={() => { }}
                    autoComplete="off">

                    <Form.Item
                        label="Plan adı"
                        name="plan_name"
                        rules={validationRules.plan_name}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Açıklama"
                        name="description"
                        rules={validationRules.description}>
                        <TextArea showCount maxLength={100} />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 1 }}
                        label="Çevrimiçi mi ?" valuePropName="checked" name={"is_online"} tooltip="Eğer seçili olur ise toplantının çevrim içi olacağı anlamına gelir.">
                        <Checkbox />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 1 }}
                        label="Anonim Anket" valuePropName="checked" name={"is_anonymous"} tooltip="Oylar gizlidir. Katılımcılar sadece kendi oylarını görebilir.">
                        <Checkbox />
                    </Form.Item>

                    <Form.Item label="Adres" name={"address"}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default memo(PlanDetailView);