
import { createSlice } from "@reduxjs/toolkit";
import { createParticipantThunkAction, findAllParticipantsThunkAction, getSelectableDatesThunkAction, participantLoginThunkAction, participantLogoutThunkAction, saveParticipantDatesThunkAction } from "./participant-thunk-action";

export const participantSlice = createSlice({
    name: 'participantSlice',
    initialState: {
        participantAuth: {
            isLoggedIn: false,
            isParticipantLoginLoading: true,
            currentParticipant: null,
            participantFailReason: null,
            plan_dates: null,
            isPlanDatesFetching: true,
            isPlanDatesSaving: false
        },
        isParticipantFetching: true,
        participants: [],
        priorityState: null
    },
    reducers: {
        setIsPlanDatesSaving: (state, { payload }) => {
            state.participantAuth.isPlanDatesSaving = !!payload;
        }
    },
    extraReducers: ({ addCase }) => {
        addCase(findAllParticipantsThunkAction.fulfilled, (state, { payload }) => {
            state.isParticipantFetching = false;
            state.participants = payload.content;
        })

        addCase(findAllParticipantsThunkAction.rejected, (state, { payload }) => {
            state.isParticipantFetching = false;
            state.participants = payload;
        })

        addCase(participantLoginThunkAction.fulfilled, (state, { payload }) => {
            state.participantAuth.currentParticipant = payload.content.participant;
            state.participantAuth.isParticipantLoginLoading = false;
            state.participantAuth.isLoggedIn = true;
            state.participantAuth.participantFailReason = null;

            localStorage.setItem("participant_token", payload.content.token)
        })

        addCase(participantLoginThunkAction.rejected, (state, { payload }) => {
            state.participantAuth.currentParticipant = null;
            state.participantAuth.isParticipantLoginLoading = false;
            state.participantAuth.participantFailReason = payload || true;
        })

        addCase(getSelectableDatesThunkAction.fulfilled, (state, { payload }) => {
            state.participantAuth.plan_dates = payload.content.tableData;
            state.priorityState = payload.content.priorityState;
            state.participantAuth.isPlanDatesFetching = false;
        })

        addCase(getSelectableDatesThunkAction.rejected, (state, _) => {
            state.participantAuth.plan_dates = null;
            state.participantAuth.isPlanDatesFetching = false;
        })

        addCase(saveParticipantDatesThunkAction.fulfilled, (state, _) => {
            state.participantAuth.isPlanDatesSaving = false;
        })

        addCase(createParticipantThunkAction.fulfilled, (state, { payload }) => {
            state.participants = [
                ...state.participants,
                payload.content
            ];
        })

        addCase(participantLogoutThunkAction.fulfilled, (state, { payload }) => {
            
        })
    }
})

export const { setIsPlanDatesSaving } = participantSlice.actions;

export default participantSlice.reducer;