import axios, { AxiosRequestConfig, HttpStatusCode } from "axios";
import { STORAGE_KEYS } from "../constants/common-constants";


/**
 * İstek için default olarak belirlenen header değerleri.
 * 
 * @param {{isFile: boolean}} params 
 * @returns 
 */
const defaultHeaderOptions = ( isFile = false) => {
    const token = localStorage.getItem(STORAGE_KEYS.token);

    let headers = {
        'Content-Type': isFile ? 'multipart/form-data' : 'application/json'
    };

    if (token) {
        headers.Authorization = `Bearer ${token}`
    }

    return { headers };

}

/**
 * Axios default değer tanımlamaları.
 */
const defaultInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

/**
 * Request configuration
 */
defaultInstance.interceptors.request.use(
    /**
     * Başarılı request istekleri
     */
    (config) => {
        return config;
    },


    /**
     * Başarısız request istekleri
     */
    (error) => {
        return Promise.reject(error);
    }
)


/**
 * Response configuration
 */
defaultInstance.interceptors.response.use(
    /**
     * Başarılı response
     */
    (response) => {
        return {
            status: response.status,
            content: response.data.content,
            message: response.data.message || response.statusText
        }
    },


    /**
     * Başarısız response (200 harici status değeriyle gelen response'lar)
     */
    (error) => {
        if(error?.response?.status === 401) {

            // ! bu alanda tekrar token generate etmek gerekiyor.
            localStorage.removeItem(STORAGE_KEYS.token);
            document.location.reload();
        }
        
        return Promise.reject(error);
    }
)

/**
 * @param {string} url
 * @param {AxiosRequestConfig} options
 */
export async function apiGet(url, options) {

    try {

        /**
         * Url değeri belirtilmemiş ise request gönderme 
         * hata dön bilgilendir.
         */
        if (!url) {
            throw new Error('GET isteği için bir url belirtilmelidir.')
        }

        const response = await defaultInstance.get(url, {
            ...defaultHeaderOptions(),
            ...options
        })

        return response;
    } catch (e) {

        /**
         * Eğer request ile ilgili veya başka bir hata oluşursa default olarak aşağıdaki response döner.
         */
        return {
            status: e.response.status,
            message: e.code,
            content: e.response.data
        };
    }
}

/**
 * @param {string} url
 * @param {AxiosRequestConfig} options
 */
export async function apiPost(url, params, options) {

    try {

        /**
         * Url değeri belirtilmemiş ise request gönderme 
         * hata dön bilgilendir.
         */
        if (!url) {
            throw new Error('POST isteği için bir url belirtilmelidir.')
        }

        const response = await defaultInstance.post(url, {...params},{
            ...defaultHeaderOptions(),
            ...options
        })

        return response;
    } catch (e) {

        /**
         * Eğer request ile ilgili veya başka bir hata oluşursa default olarak aşağıdaki response döner.
         */
        return {
            status: e?.response?.status || HttpStatusCode.RequestTimeout,
            message: e.code,
            content: e?.response?.data || ""
        };
    }
}


export async function apiDelete(url, options) {
    try {
        if(!url) {
            throw new Error('URL_NOT_FOUND_FOR_DELETE_REQUEST')
        }

        const response = await defaultInstance.delete(url,{
            ...defaultHeaderOptions(),
            ...options
        })

        return response;

    } catch (e) {
        return {
            status: e.response.status,
            message: e.code,
            content: e.response.data
        };
    }
}

export async function apiPut(url, params, options) {

    try {

        /**
         * Url değeri belirtilmemiş ise request gönderme 
         * hata dön bilgilendir.
         */
        if (!url) {
            throw new Error('PUT isteği için bir url belirtilmelidir.')
        }

        const response = await defaultInstance.put(url, {...params},{
            ...defaultHeaderOptions(),
            ...options
        })

        return response;
    } catch (e) {

        /**
         * Eğer request ile ilgili veya başka bir hata oluşursa default olarak aşağıdaki response döner.
         */
        return {
            status: e.response.status,
            message: e.code,
            content: e.response.data
        };
    }
}