import { forwardRef } from "react"
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import bootstrapPlugin from '@fullcalendar/bootstrap5';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css'

const TPCalendar = forwardRef(({ handleDateClick, initialEvents, handleSelection, removeDate }, ref) => {


    const CalendarContent = (eventInfo) => {
        return (
            <>
                <b>{eventInfo.timeText}</b>
                <span onClick={() => removeDate(eventInfo)} style={{ fontSize: 16, fontWeight: "bold" }} > &nbsp; X</span>
                <i>{eventInfo.event.title}</i>
            </>
        )
    }

    return (
        <FullCalendar
            ref={ref}
            plugins={[timeGridPlugin, interactionPlugin, bootstrapPlugin]}
            headerToolbar={{ left: "prev,next", center: "title", right: "today" }}
            buttonText={{ today: "Bugün" }}
            allDayText="Gün"
            initialView="timeGridWeek"
            dateClick={handleDateClick}
            locale="tr"
            selectable
            select={handleSelection}
            eventContent={CalendarContent}
            slotMinTime="08:00:00"
            slotMaxTime="19:00:00"
            themeSystem="bootstrap5"
            contentHeight={'auto'}
            eventColor='#88c929'
            firstDay={1}
            initialEvents={initialEvents}
        />
    )
})

export default TPCalendar;