import { createSlice } from "@reduxjs/toolkit";
import { getUserInformationsThunkAction, loginThunkAction } from "./user-thunk-action";


export const userSlice = createSlice({
    name: 'userSlice',
    initialState: {
        currentUser: {
            id: null,
            email: null
        }
    },
    reducers: {},
    extraReducers: ({ addCase }) => {

        addCase(loginThunkAction.fulfilled, (state, { payload }) => {
            const { content } = payload;
            const { token, ...currentUserData } = content;
            state.currentUser = currentUserData;
        })

        addCase(getUserInformationsThunkAction.fulfilled, (state, { payload }) => {
            state.currentUser = payload.content !== "USER_NOT_FOUND" ? payload.content : {}
        })
    }
})

export const actions = userSlice.actions;

export default userSlice.reducer;