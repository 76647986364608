import { Button, Card, Col, Popconfirm, Row, Space, Table, Select } from "antd";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decreaseStep, increaseStep, setParticipants } from "../../../../store/onboard/onboard-slice";
import TPSelect from "../../../../components/tp-select/tp-select";
import AddNewParticipantModalContentView from "../../../../views/participant/add-new-participant-modal-content-view";
import { createParticipantThunkAction } from "../../../../store/participant/participant-thunk-action";
import useToast from '../../../../hooks/useToast';


const AddParticipants = () => {

    // refs
    const addNewParticipantFormRef = useRef();

    // redux
    const dispatch = useDispatch();
    const participants = useSelector(state => state.onboardSlice.onboardFormData.participants);
    const has_priority = useSelector(state => state.onboardSlice.onboardFormData.plan.has_priority);
    const participantSelectData = useSelector(state => state.participantSlice.participants)

    // local states
    const [data, setData] = useState(Array.isArray(participants) && participants.length ? participants : []);
    const [selectedPerson, setSelectedPerson] = useState("")
    const [showAddNewParticipantModal, setShowAddNewParticipantModal] = useState(false);

    // hooks
    const { contextHolder, showErrorToast } = useToast()

    const onPriortyChange = ({ value, record }) => {
        setData(prevData => {
            const oldData = JSON.parse(JSON.stringify(prevData));
            const index = oldData.findIndex(p => p.id === record.id);
            oldData[index].priority_order = value;

            return oldData;
        })
    }

    const getColumns = () => {
        const columns = [
            {
                title: 'No',
                dataIndex: 'id',
                width: '10%',
                sorter: (a, b) => a.id - b.id
            },
            {
                title: 'Unvan',
                dataIndex: 'title',
            },
            {
                title: 'Ad',
                dataIndex: 'name',
            },
            {
                title: 'Soyad',
                dataIndex: 'surname',
            },
            {
                title: 'Kurum',
                dataIndex: 'institution',
            },
            {
                title: 'İşlem',
                dataIndex: 'actions',
                key: 'x',
                render: (_, record) =>
                    data.length >= 1 ? (
                        <Popconfirm
                            title="Bu kişiyi silmek istediğinize emin misiniz ?"
                            cancelText="İptal"
                            okText="Evet"
                            onConfirm={() => handleDelete(record.id)}>

                            <Button type="primary" danger>Sil</Button>

                        </Popconfirm>
                    ) : null,
            },
        ];

        if (has_priority) {
            const last = columns.pop();
            columns.push(
                {
                    title: 'Öncelik',
                    key: 'priorty_order',
                    render: (_, record) => (
                        <Select
                            style={{ width: 100 }}
                            placeholder="Öncelik Seçimi"
                            defaultValue={record.priority_order}
                            onChange={(value) => onPriortyChange({ value, record })}
                            options={[
                                {
                                    value: 'HIGH',
                                    label: 'Yüksek',
                                },
                                {
                                    value: 'MEDIUM',
                                    label: 'Normal',
                                },
                                {
                                    value: 'LOW',
                                    label: 'Düşük',
                                },
                            ]}
                        />
                    )
                },
                last
            )
        }

        return columns;
    }

    const handleDelete = (key) => {
        const newData = data.filter((item) => item.id !== key);
        setData(newData);
    };

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };

    const handlePrevButton = () => {
        dispatch(setParticipants(data))
        dispatch(decreaseStep())
    };

    const handleNextButton = () => {
        dispatch(increaseStep())
        dispatch(setParticipants(data))
    };

    const selectOptions = () => {
        return participantSelectData.map(option => ({
            value: JSON.stringify(option),
            label: (
                <Row justify={"space-between"} align={"middle"} style={{ width: '100%' }}>
                    <Col span={12}>{option.name} {option.surname}</Col>
                    <Col span={6}>{option.institution}</Col>
                </Row>
            )
        }))
    }

    const onSelect = (value) => {
        setSelectedPerson(JSON.parse(value));
    }

    const onPersonAdd = () => {

        const isAllreadyAdded = data.some((d) => d.id === selectedPerson.id);

        if (isAllreadyAdded) {
            showErrorToast("Hata", "Katılımcı daha önce eklenmiş !");
            return;
        }

        if (selectedPerson) {
            setData(prev => [
                ...prev,
                {
                    ...selectedPerson,
                    priority_order: "MEDIUM"
                }
            ]);

        }

        setSelectedPerson(null)
    }

    const onAddNewParticipant = () => {
        setShowAddNewParticipantModal(true)
    }

    const onAddNewParticipantFinish = () => {
        const values = addNewParticipantFormRef.current.getFieldsValue();
        dispatch(createParticipantThunkAction(values));
        setShowAddNewParticipantModal(false);
        addNewParticipantFormRef.current.resetFields()
    }

    const groupLabel = {
        labels: ['İsim Soyisim', 'Kurum'],
        layout: [12, 6]
    }

    return (
        <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
            {contextHolder}
            <Card title="Katılımcıları Belirleyin !" style={{ width: 900 }}>

                <Row align={"middle"} justify="end" style={{ marginBottom: 10 }}>
                    <Space>
                        <TPSelect
                            filterOption={(input, option) => (option?.value || '').toLowerCase().includes(input?.trim().toLowerCase())}
                            style={{ width: "500px" }}
                            addNewText="Yeni Katılımcı Ekle"
                            onAddNew={onAddNewParticipant}
                            groupLabel={groupLabel}
                            options={selectOptions()}
                            onSelect={onSelect}
                            value={selectedPerson ? JSON.stringify(selectedPerson) : null}
                            isModalOpen={showAddNewParticipantModal}
                            modalProps={
                                {
                                    onOk: () => { onAddNewParticipantFinish() },
                                    onCancel: () => { setShowAddNewParticipantModal(false) },
                                    modalContent: (
                                        <AddNewParticipantModalContentView
                                            ref={addNewParticipantFormRef}
                                            onFinish={() => { onAddNewParticipantFinish() }} />
                                    )
                                }
                            }
                        />
                        <Button disabled={!selectedPerson} onClick={onPersonAdd} size="middle" type="primary">Ekle</Button>
                    </Space>
                </Row>

                <Row align={"middle"} justify={"center"}>
                    <Col span={24}>
                        <Table columns={getColumns()} dataSource={data} onChange={onChange} />
                    </Col>
                </Row>

                <Row align={"middle"} justify={"center"} style={{ gap: 20, marginTop: 25 }}>
                    <Button type="link" htmlType="button" onClick={handlePrevButton}>Geri</Button>
                    <Button size="large" type="primary" onClick={handleNextButton} htmlType="button">İleri</Button>
                </Row>
            </Card>
        </Row >


    )

}

export default AddParticipants;