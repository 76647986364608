import useNotification from "antd/es/notification/useNotification"


const useToast = () => {

    const [toastApi, contextHolder] = useNotification();

    const showToast = (message, description, variant = "error", placement = "topRight") => {
        toastApi[variant]({
            message,
            description,
            placement
        })
    }

    const showErrorToast = (message, description, placement = "topRight") => {
        toastApi.error({
            message,
            description,
            placement
        })
    }

    return {
        contextHolder,
        toastApi,
        showToast,
        showErrorToast
    }
}

export default useToast;