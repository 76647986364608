import { arrayRange } from "./array-utils";
import ExcelJS from 'exceljs';


export const excelExport = ({ rows, fileName, sheetName }) => {
    const beRows = rows;

    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(sheetName || fileName);
    sheet.properties.defaultRowHeight = 80;

    const headerCells = Object.keys(beRows.content[0]).map((_, index) => {
        const row = 1;
        const col = index + 1;
        return { row, col };
    });

    headerCells.forEach(hCell => {
        const currentCell = sheet.getCell(hCell.row, hCell.col);

        currentCell.style = {
            border: {
                top: { style: "thin", color: { argb: "9E9E9E" } },
                left: { style: "thin", color: { argb: "9E9E9E" } },
                bottom: { style: "thin", color: { argb: "9E9E9E" } },
                right: { style: "thin", color: { argb: "9E9E9E" } },
            },
            fill: {
                type: "pattern",
                pattern: "solid",
                fgColor: {
                    argb: "BDBDBD"
                },
            },
            font: {
                name: "Arial",
                family: 6,
                size: 14,
                bold: true,
            }
        }
    })

    sheet.columns = Object.keys(beRows.content[0]).map((key) => {
        return {
            header: key.toLocaleUpperCase(),
            key: key,
            width: 30
        }
    })

    beRows.content.forEach((row => {
        sheet.addRow(row);
    }))

    const coloredColumns = arrayRange(1, Object.keys(beRows.content[0]).length, 1);

    coloredColumns.forEach(((index) => {
        const getRow = sheet.getColumn(index);
        getRow?.eachCell(cell => {

            const cellValue = sheet.getCell(cell?.address).value;

            if (cellValue === "Evet") {
                sheet.getCell(cell?.address).fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "4CAF50" },
                };
            }

            if (cellValue === "Hayır") {
                sheet.getCell(cell?.address).fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "F44336" },
                };
            }
        })
    }))

    workbook.xlsx.writeBuffer().then(function (data) {
        const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${fileName.toLocaleLowerCase("tr").split(" ").join("-")}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
    });
}