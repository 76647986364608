import { format } from 'date-fns'
import { tr } from 'date-fns/locale'

export const formatDate = (date, isTimeShow = true, dateFormat) => (
    format(new Date(date), dateFormat || `dd/MM/yyyy ${isTimeShow ? 'HH:mm' : ''}`, { locale: tr })
)

/**
 * Zaman değerleri için tek haneli olan ve sıfır gerekli olduğu durumlarda kullanılır.
 * 
 * @param {number} num 
 * @returns 
 */
export const zeroPad = (num) => String(num).padStart(2, "0");

/**
 * Zaman değeri tek haneli ise bu formatter üzerinden zaman değeri alınır.
 * 
 * @param {number} value 
 * @returns 
 */
export const formatSingleDigit = (value) => String(value).length === 1 ? zeroPad(value) : value;
